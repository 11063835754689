var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{staticClass:"d-flex justify-space-between flex-column flex-md-row",attrs:{"xs12":""}},[_c('span',{staticClass:"display-1 px-5 pb-5 font-weight-medium"},[_vm._v(_vm._s(_vm.registry.name))]),(_vm.$auth.check() && _vm.registry.category && _vm.hasPermission)?_c('v-btn',{staticClass:"mb-2 mb-md-0",attrs:{"color":"secondary"},on:{"click":_vm.add}},[_vm._v("Adaugă intrare în registru")]):_vm._e()],1),_c('v-flex',{attrs:{"ml-0":"","ml-lg-5":"","xs12":""}},[_c('v-card',[_c('v-card-title',[_c('v-menu',{attrs:{"min-width":"290px","offset-y":"","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"append-icon":"mdi-close","hide-details":"","label":"Alegeți luna","prepend-icon":"mdi-calendar","readonly":""},on:{"click:append":_vm.clearMonth},model:{value:(_vm.month),callback:function ($$v) {_vm.month=$$v},expression:"month"}},on))]}}]),model:{value:(_vm.monthMenu),callback:function ($$v) {_vm.monthMenu=$$v},expression:"monthMenu"}},[_c('v-date-picker',{attrs:{"color":"secondary","no-title":"","reactive":"","scrollable":"","type":"month"},on:{"change":function($event){return _vm.formatMonth(_vm.filterQuery.month)}},model:{value:(_vm.filterQuery.month),callback:function ($$v) {_vm.$set(_vm.filterQuery, "month", $$v)},expression:"filterQuery.month"}})],1),_c('v-spacer'),_c('v-select',{attrs:{"items":_vm.years,"clearable":"","hide-details":"","label":"Alegeți anul"},on:{"change":_vm.applyYear},model:{value:(_vm.filterQuery.year),callback:function ($$v) {_vm.$set(_vm.filterQuery, "year", $$v)},expression:"filterQuery.year"}}),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","hide-details":"","label":"Caută Înregistrare","single-line":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getData.apply(null, arguments)}},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_c('v-data-table',{attrs:{"footer-props":{
                    'items-per-page-options': _vm.$vuetifyTableItemsPerPage
                },"headers":_vm.headers,"items":_vm.data.items,"loading":_vm.loading,"options":_vm.data.pagination,"server-items-length":_vm.data.total},on:{"update:options":[function($event){return _vm.$set(_vm.data, "pagination", $event)},_vm.getData]},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"font-weight-medium text-no-wrap"},[_vm._v(" "+_vm._s(item.number)+" / "+_vm._s(item.year)+" ")])]}},{key:"item.direction",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"font-weight-medium text-no-wrap"},[_vm._v(" "+_vm._s(item.direction)+" ")])]}},{key:"item.info",fn:function(ref){
                var item = ref.item;
return [_c('v-layout',{attrs:{"column":""}},[_c('v-flex',{directives:[{name:"show",rawName:"v-show",value:(item.meta && _vm.parse(item.meta).tag),expression:"item.meta && parse(item.meta).tag"}],attrs:{"pt-2":""}},[_c('v-chip',{attrs:{"color":"primary","label":"","x-small":""}},[_vm._v(_vm._s(_vm.parse(item.meta) && _vm.parse(item.meta).tag ? _vm.parse(item.meta).tag : ""))])],1),_c('v-flex',{staticClass:"title",attrs:{"py-2":""}},[_vm._v(" "+_vm._s(item.name)+" ")]),(_vm.isHotarare || _vm.isProject)?_c('v-flex',{attrs:{"pb-2":""}},[_c('v-btn',{attrs:{"x-small":""},on:{"click":function($event){return _vm.open(item)}}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-file")]),_vm._v(" Documentație Proiect ")],1)],1):_vm._e()],1)]}},{key:"item.state",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.display_date))+" ")]}},{key:"item.directed",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(_vm.parse(item.meta) && _vm.parse(item.meta).directed ? _vm.parse(item.meta).directed : "")+" ")])]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column flex-md-row justify-end"},[(_vm.isHotarare)?_c('v-btn',{staticClass:"my-1 my-md-0",attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.show(item)}}},[_vm._v("Vizualizează")]):_c('v-btn',{staticClass:"my-1 my-md-0",attrs:{"disabled":!item.src,"href":item.src,"target":"_blank","color":"primary","small":""}},[_vm._v("Vizualizează")]),(_vm.$auth.check() && _vm.hasPermission)?_c('v-btn',{staticClass:"ml-0 ml-md-2 my-1 my-md-0",attrs:{"color":"success","small":""},on:{"click":function($event){return _vm.edit(item)}}},[_vm._v("Editează")]):_vm._e(),(_vm.$auth.check() && _vm.hasPermission)?_c('v-btn',{staticClass:"ml-0 ml-md-2 my-1 my-md-0",attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.destroy(item)}}},[_vm._v("Șterge")]):_vm._e()],1)]}}])})],1),_c('v-dialog',{attrs:{"fullscreen":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-title',{attrs:{"primary-title":""}},[_c('p',{staticClass:"headline"},[_vm._v(_vm._s(_vm.record.name))]),_c('v-spacer')],1),_c('v-card-text',{staticClass:"ql-editor",domProps:{"innerHTML":_vm._s(_vm.record.documentation)}}),_c('v-card-text',[_c('p',{staticClass:"headline"},[_vm._v("Atașamentele Documentației")]),_vm._l((_vm.record.docs),function(doc,index){return _c('a',{key:index,staticStyle:{"text-decoration":"none"},attrs:{"href":doc.url,"target":"_blank"}},[_c('v-card',{staticClass:"my-2",attrs:{"hover":""}},[_c('v-card-text',{staticClass:"subtitle-2"},[_vm._v(_vm._s(doc.name))])],1)],1)})],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }